import { defineStore } from 'pinia'
import ControlEnvioService from '../services/controlEnvio'
const _ControlEnvioService = new ControlEnvioService()
export const useVer = defineStore('ver', {
  state: () => {
    return {
      envio: [],
      id: null
    }
  },
  getters: {
    _envio: (state) => {
      return state.envio.sort((a, b) => a.ItemCode - b.ItemCode).sort((a, b) => a.Status - b.Status)
    },
    _id: (state) => state.id
  },
  actions: {
    ver () {
      _ControlEnvioService.ver(this.id).then(({ data }) => {
        data = data.map((item) => {
          return {
            ...item,
            Justificacion: null
          }
        })
        this.envio = data
      })
    },
    confirmarRecepcion () {
      return new Promise((resolve, reject) => {
        const data = this.envio
        const confirmarRecepcion = this.envio.filter((item) => item.Status === true)
        if (confirmarRecepcion.length === 0) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ message: 'Debe seleccionar al menos un producto' })
        } else {
          localStorage.setItem('confirmarRecepcion', JSON.stringify(data))
          resolve()
        }
      })
    },
    modificarRow (posicionEditar, data) {
      this.envio[posicionEditar].FecVen = data.FecVen
      this.envio[posicionEditar].ItemCode = data.ItemCode
      this.envio[posicionEditar].ItemName = data.ItemName
      this.envio[posicionEditar].Justificacion = data.Justificacion
      this.envio[posicionEditar].NumLote = data.NumLote
      this.envio[posicionEditar].Quantity = data.Quantity
      this.envio[posicionEditar].Status = true
      this.envio[posicionEditar].AgrupId = data.AgrupId
      this.envio[posicionEditar].IdItemCode = data.IdItemCode
      // for (const row of this.envio) {
      //   if (row.RowId === data.RowId) {
      //     row.FecVen = data.FecVen
      //     row.ItemCode = data.ItemCode
      //     row.ItemName = data.ItemName
      //     row.Justificacion = data.Justificacion
      //     row.NumLote = data.NumLote
      //     row.Quantity = data.Quantity
      //     row.Status = true
      //     row.AgrupId = data.AgrupId
      //     row.IdItemCode = data.IdItemCode
      //   }
      // }
    },
    searchArticulo (data) {
      return new Promise((resolve) => {
        _ControlEnvioService.searchArticulo(data).then(({ data }) => {
          resolve(data)
        })
      })
    },
    duplicateRow (data, index) {
      // data.RowId = Math.max(...this.envio.map(item => item.RowId))
      const dataLinea = { ...data }
      dataLinea.RowId = null
      const arrayTemp = [...this.envio]
      arrayTemp.splice(index, 0, dataLinea)
      this.envio = arrayTemp
    },
    addRow (data) {
      const { EnvioId } = this.envio[0]
      // data.RowId = Math.max(...this.envio.map(item => item.RowId))
      const dataLinea = { ...data }
      dataLinea.RowId = null
      dataLinea.EnvioId = EnvioId
      this.envio = [...this.envio, dataLinea]
    }
  }
})
