<template>
  <div>
    <Toast/>
    <div class="flex justify-between p-4 bg-white ">
      <div class="flex flex-col">
        <span class="text-xs font-bold">N° Envio</span>
        <span class="text-xs">{{ header.EnvioId }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-xs font-bold">Guia</span>
        <span class="text-xs">{{ header.Guia }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-xs font-bold">Fecha</span>
        <span class="text-xs">{{ header.DocDate }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-xs font-bold">Bodega Destino</span>
        <span class="text-xs">{{ header.WhsName }}</span>
      </div>
    </div>
  </div>
  <div class="w-full">
    <Table :rows="storeVer.envio"/>
  </div>
  <div>
    <div class="flex justify-end p-4 bg-white gap-2">
      <ConfirmPopup/>
      <Button label="Cancelar" severity="danger" @click="onCancelEntrega()"/>
      <Button label="Confirmar" @click="onConfirmarRecepcion()"/>
    </div>
  </div>
  <Modificar ref="modificarDialog"/>
  <Toast />
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router'
import { defineAsyncComponent, onMounted, computed, ref } from 'vue'
import { useVer } from '../../../store/ver.store'
import { useCrear } from '../../../store/crear.store'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import Swal from 'sweetalert2'

const toast = useToast()
const confirm = useConfirm()
const Table = defineAsyncComponent(() => import('../../components/ver/table.vue'))
const Modificar = defineAsyncComponent(() => import('../../components/ver/modificarDialog.vue'))
const route = useRoute()
const router = useRouter()
const storeVer = useVer()
const storeCrear = useCrear()
const header = computed(() => {
  const temp = storeVer._envio[0]
  return {
    EnvioId: temp ? temp.EnvioId : '',
    Guia: temp ? temp.Guia : '',
    DocDate: temp ? temp.Date : '',
    WhsName: temp ? temp.WhsName : ''
  }
})
onMounted(() => {
  storeVer.id = route.params.EnvioId
  storeVer.ver()
})
const modificarDialog = ref(null)
const onEditLine = (data, index) => {
  modificarDialog.value.onVisible({ valor: true, data, add: false, posicion: index })
}

const onConfirmarRecepcion = () => {
  storeVer.confirmarRecepcion().then(() => {
    confirm.require({
      target: event.currentTarget,
      message: 'Agregar Medicamento Apartados?',
      icon: 'pi pi-exclamation-triangle',
      position: 'left',
      accept: () => {
        storeCrear.validar().then((valid) => {
          if (valid.error) {
            toast.add({ severity: 'error', summary: valid.error, life: 5000 })
            onEditLine(storeVer.envio[valid.index], parseInt(valid.index))
          } else {
            router.push({ name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica.apartado' })
          }
        })
      },
      reject: () => {
        storeCrear.registartEntMercancia().then((response) => {
          Swal.fire({
            title: 'Registro exitoso',
            text: 'Se registro la recepcion tecnica con exito',
            icon: 'success'
          }).then(() => {
            router.push({ name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica' })
          })
        }).catch(error => {
          toast.add({ severity: 'error', summary: error.error, life: 5000 })
          onEditLine(storeVer.envio[error.index], parseInt(error.index))
          // for (const errorElement of error) {
          //   console.log('errorElement', errorElement)
          //   toast.add({ severity: 'error', summary: errorElement, life: 3000 })
          // }
        })
      }
    })
  }).catch(e => {
    toast.add({ severity: 'error', summary: e.message, life: 3000 })
  })
}
const onCancelEntrega = () => {
  router.go(-1)
  localStorage.removeItem('confirmarRecepcion')
}
</script>
